<template>
    <div class="hero min-h-screen z-10">
        <video muted loop autoplay playsinline preload="auto" class="object-cover min-w-full min-h-full z-20">
            <source src="/videos/gym-with-bumper-plates.webm" type="video/webm">
            <source src="/videos/gym-with-bumper-plates.mp4" type="video/mp4">
        </video>
        <div class="hero-overlay bg-gray-900/60 z-30"></div>
        <div class="hero-content text-center text-neutral-content z-40">
            <div class="max-w-2xl">
                <h1 class="mb-5 text-3xl lg:text-7xl font-bold text-white">{{ title }}</h1>
                <input type="text" id="pac-input" placeholder="Type an address, suburb, city or postcode" class="w-full rounded p-3 text-gray-800 text-center"><br>
                <button class="btn btn-primary mt-5" @click="useCurrentLocation">
                    <Icon size="20"><MapMarkerAlt></MapMarkerAlt></Icon>
                    Use my current location
                    <span class="loading loading-bars loading-md" v-show="isFetchingLocation"></span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import {Icon} from "@vicons/utils";
import MapMarkerAlt from '@vicons/fa/MapMarkerAlt';
import { router } from '@inertiajs/vue3'

export default {
    components: {
        Icon,
        MapMarkerAlt
    },
    data() {
        return {
            isFetchingLocation: false
        }
    },
    computed: {
        title() {
            if (this.$countryCode === "NZ") {
                return "Find and compare gyms all over Aotearoa";
            } else if (this.$countryCode === "AU") {
                return "Find and compare gyms all over Australia";
            }
        }
    },
    mounted() {
        this.initAutocomplete()
    },
    methods: {
        async initAutocomplete() {
            const sessionToken = window.crypto && crypto.randomUUID ? crypto.randomUUID() : Math.random().toString(36);
            const { Place } = await google.maps.importLibrary("places");
            const input = document.getElementById("pac-input");
            const options = {
                componentRestrictions: { country: this.$countryCode.toLowerCase() },
                fields: ['place_id', 'name', 'geometry', 'formatted_address'],
                languageCode: "en",
                regionCode: this.$countryCode,
                //types: ['address'],
                sessionToken: sessionToken,
            };
            const autocomplete = new google.maps.places.Autocomplete(input, options);
            autocomplete.addListener("place_changed", (ev) => {
                const place = autocomplete.getPlace();
                if (place && place.geometry) {
                    router.get(route('places.search'), {
                        lat: place.geometry.location.lat(),
                        lng: place.geometry.location.lng(),
                        formatted_address: place.formatted_address
                    });
                } else {
                    alert("Sorry, please try another location");
                    console.error("Autocomplete place not found", document.getElementById("pac-input").value);
                }
            })
        },
        useCurrentLocation() {
            this.isFetchingLocation = true;
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        router.get(route('places.search'), {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude
                        });
                    },
                    () => {
                        // something went wrong getting the location
                        router.get(route('places.search'));
                    },
                );
            } else {
                // Browser doesn't support Geolocation
                router.get(route('places.search'));
            }
        }
    }
}

</script>

<style scoped>

</style>
