<script setup>
import feature1Image from '@/assets/landing/feature-1.jpg';
import feature2Image from '@/assets/landing/feature-3.jpg';
import feature3Image from '@/assets/landing/feature-2.jpg';
import feature4Image from '@/assets/landing/feature-4.jpg';
</script>

<template>
    <section class="relative py-8 lg:py-20">
        <div class="absolute start-[10%] z-0">
            <div
                class="pointer-events-none aspect-square w-60 rounded-full bg-gradient-to-r from-primary/10 via-violet-500/10 to-purple-500/10 blur-3xl [transform:translate3d(0,0,0)] lg:w-[600px]"
            ></div>
        </div>

        <div class="container">
            <div class="flex flex-col items-center">
                <h2 class="inline text-4xl font-semibold">{{ title }}</h2>

                <p class="mt-4 text-lg sm:text-center">
                    Our mission is to make {{ countryCode === 'NZ' ? 'New Zealanders' : 'Australians' }} fitter sooner by providing a platform to find and compare gyms and fitness providers throughout {{ countryCode === 'NZ' ? 'Aotearoa' : 'Australia' }}.
                </p>
            </div>

            <div class="relative z-[2] mt-8 grid gap-8 lg:mt-20 lg:grid-cols-2 lg:gap-12">
                <div
                    class="overflow-hidden rounded-lg bg-base-200 shadow-md transition-all hover:shadow-xl"
                >
                    <img alt="saas img" class="overflow-hidden rounded-ss-lg" :src="feature1Image" />
                </div>

                <div class="lg:mt-8">
                    <h3 class="mt-2 text-4xl font-semibold">Find a gym</h3>
                    <p class="mt-2 text-base font-medium">
                        Our gym search tool is your ultimate destination for finding the perfect fitness center tailored to your needs. Whether you're into functional training, hardcore weightlifting, yoga, or group classes, we've got you covered. Our platform is your go-to resource for gym hunting in your area.
                    </p>

                    <div class="mt-16 inline-flex gap-3">
                        <a :href="route('places.search')" class="btn btn-primary">Find a gym near me</a>
                    </div>
                </div>
            </div>

            <div class="mt-8 grid gap-8 lg:mt-20 lg:grid-cols-2 lg:gap-12">
                <div>
                    <h3 class="mt-2 text-3xl font-semibold">Compare gyms near me</h3>
                    <p class="mt-2 text-base">
                        Not sure which gym is right for you? Our intelligent algorithm takes your preferences and fitness goals into account to recommend the best-suited options. Whether you're a seasoned athlete or a beginner, we'll help you find a gym that fits your lifestyle and motivates you to reach your goals.
                    </p>

                    <div class="mt-16 inline-flex gap-3">
                        <a :href="route('places.compare')" class="btn btn-primary">Compare gyms now</a>
                    </div>
                </div>

                <div class="order-first lg:order-last">
                    <div
                        class="overflow-hidden rounded-lg shadow-md transition-all hover:shadow-xl"
                    >
                        <img alt="saas img" class="overflow-hidden rounded-ss-lg" :src="feature2Image" />
                    </div>
                </div>
            </div>

            <div class="mt-8 grid gap-8 lg:mt-20 lg:grid-cols-2 lg:gap-12">
                <div
                    class="overflow-hidden rounded-lg bg-base-200 shadow-md transition-all hover:shadow-xl"
                >
                    <img alt="saas img" class="overflow-hidden rounded-ss-lg" :src="feature3Image" />
                </div>

                <div class="lg:mt-8">
                    <h3 class="mt-2 text-3xl font-semibold">Become a member</h3>
                    <p class="mt-2 text-base">
                        Ready to take the plunge? Whether you're looking to build muscle, lose weight, or improve your overall health and well-being, finding the right gym is the first step. Let us help you make that journey smoother, more enjoyable, and ultimately successful. Explore our website now and embark on a fitness adventure tailored just for you!
                    </p>

                    <div class="mt-16 inline-flex gap-3">
                        <a :href="route('places.search')" class="btn btn-primary">Find gyms near me</a>
                    </div>
                </div>
            </div>

            <div class="mt-8 grid gap-8 lg:mt-20 lg:grid-cols-2 lg:gap-12">
                <div>
                    <h3 class="mt-2 text-3xl font-semibold">Exclusive deals and offers</h3>
                    <p class="mt-2 text-base">
                        Who doesn't love a good deal? Discover special promotions, discounts, and trial offers available exclusively through our platform. Save money while getting access to top-notch facilities and services.
                    </p>

                    <div class="mt-16 inline-flex gap-3">
                        <a :href="route('deals-and-promos')" class="btn btn-primary">View deals & promos</a>
                    </div>
                </div>

                <div class="order-first lg:order-last">
                    <div
                        class="overflow-hidden rounded-lg shadow-md transition-all hover:shadow-xl">
                        <img alt="saas img" class="overflow-hidden rounded-ss-lg" :src="feature4Image" />
                    </div>
                </div>
            </div>

        </div>
    </section>
</template>

<script>
export default {
    data() {
        return {
            countryCode: import.meta.env.VITE_APP_COUNTRY
        }
    },
    computed: {
        title() {
            if (this.countryCode === "NZ") {
                return "New Zealand's No 1 fitness hub is here!";
            } else if (this.countryCode === "AU") {
                return "Australia's No 1 fitness hub is here!";
            }
        }
    }
}
</script>
