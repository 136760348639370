<script setup>
import { ref, onMounted } from 'vue'
import heroImage from '@/assets/landing/fitchoice-search-2.png'
import googleImage from '@/assets/logo/google.svg'
import microsoftImage from '@/assets/logo/microsoft.svg'
import netflixImage from '@/assets/logo/netflix.svg'
import spotifyImage from '@/assets/logo/spotify.svg'
import paypalImage from '@/assets/logo/paypal.svg'
import Card3d from 'card3d'

const effectRef = ref(null)
onMounted(() => {
    if (effectRef.value) {
        new Card3d(effectRef.value, { perspective: 1000, fullPageListening: true })
    }
})
</script>


<template>
    <section class="py-8 lg:py-20" id="home" data-theme="cupcake">
        <div class="container">
            <div class="grid gap-12 lg:grid-cols-2">
                <div>
                    <h2 class="text-4xl font-black tracking-tighter lg:text-6xl lg:leading-none">
                        Put your gym on the map<br>
                        <span class="text-secondary">List your fitness business with us</span>
                    </h2>
                    <p class="mt-8 text-lg">
                        <strong>Increase Visibility:</strong> Get your gym noticed by fitness enthusiasts in your area and beyond. Our platform connects you with potential members actively seeking fitness options.<br><br>
                        <strong>Boost Membership:</strong> Attract more members to your gym and keep your facility bustling with activity. Our user-friendly interface makes it easy for potential members to discover your gym and sign up.<br><br>
                        <strong>Targeted Audience:</strong> Reach your target demographic effectively. Our platform allows users to filter gyms based on location, amenities, classes offered, and more, ensuring that your gym is seen by those most likely to join.
                    </p>
                    <div class="mt-16 inline-flex gap-3">
                        <a :href="route('listing-plans')" class="btn btn-primary">Get started</a>
                        <a :href="route('list-on-fitchoice')" class="btn">Why list on FitChoice</a>
                    </div>
                </div>

                <div class="w-full">
                    <div
                        class="rounded-2xl bg-gradient-to-r from-indigo-200 via-red-200 to-purple-300 p-3"
                        ref="effectRef"
                        data-card3d=""
                        data-card3d-perspective="1000"
                        data-card3d-full-page-listening="true"
                    >
                        <img alt="SaaS" class="rounded-lg" :src="heroImage" />
                    </div>
                </div>
            </div>

<!--            <h2 class="mt-12 text-center text-2xl font-semibold text-base-content/60 lg:mt-32">-->
<!--                Featured gyms-->
<!--            </h2>-->

<!--            <div class="mt-12 grid grid-cols-2 justify-center gap-8 sm:grid-cols-3 md:grid-cols-5">-->
<!--                <img-->
<!--                    class="mx-auto h-8 cursor-pointer grayscale transition-all duration-500 hover:grayscale-0"-->
<!--                    :src="googleImage"-->
<!--                    alt="google logo"-->
<!--                />-->
<!--                <img-->
<!--                    class="mx-auto h-6 cursor-pointer grayscale transition-all duration-500 hover:grayscale-0"-->
<!--                    :src="microsoftImage"-->
<!--                    alt="microsoft logo"-->
<!--                />-->
<!--                <img-->
<!--                    class="mx-auto hidden h-6 cursor-pointer grayscale transition-all duration-500 hover:grayscale-0 md:inline"-->
<!--                    :src="netflixImage"-->
<!--                    alt="netflix logo"-->
<!--                />-->
<!--                <img-->
<!--                    class="mx-auto hidden h-8 cursor-pointer grayscale transition-all duration-500 hover:grayscale-0 md:inline"-->
<!--                    :src="spotifyImage"-->
<!--                    alt="spotify logo"-->
<!--                />-->
<!--                <img-->
<!--                    class="mx-auto hidden h-8 cursor-pointer grayscale transition-all duration-500 hover:grayscale-0 sm:inline"-->
<!--                    :src="paypalImage"-->
<!--                    alt="paypal logo"-->
<!--                />-->
<!--            </div>-->
        </div>
    </section>
</template>
