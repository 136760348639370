<script setup>
import { ref, onMounted } from 'vue'
import Card3d from 'card3d';

const effectRef = ref(null)
onMounted(() => {
    if (effectRef.value) {
        new Card3d(effectRef.value, { perspective: 1000, fullPageListening: true })
    }
})
</script>


<template>
    <div class="container">
        <h2 class="mt-12 text-center text-2xl font-semibold text-base-content/60 mb-5">
            Help us to find the right gym for you
        </h2>

        <div class="grid place-content-center">
            <Carousel :items-to-show="1" :transition="500" ref="carousel">
                <Slide :key="0">
                    <div class="grid w-full place-content-center">
                        <ul class="steps mb-2">
                            <li class="step step-secondary"></li>
                            <li class="step"></li>
                            <li class="step"></li>
                            <li class="step"></li>
                            <li class="step"></li>
                        </ul>
                        <div class="font-bold text-lg text-left mb-2">I want ...</div>
                        <div class="form-control mb-2">
                            <label class="flex items-center cursor-pointer">
                                <input type="radio" name="radio-10" class="radio radio-lg checked:bg-primary mr-2"
                                       value="alone"
                                       v-model="form.training_style"
                                       @change="goToSlide(1)" />
                                <span class="label-text font-bold">to workout by myself</span>
                            </label>
                        </div>
                        <div class="form-control mb-2">
                            <label class="flex items-center cursor-pointer">
                                <input type="radio" name="radio-10" class="radio radio-lg checked:bg-primary mr-2"
                                       value="group"
                                       v-model="form.training_style"
                                       @change="goToSlide(1)" />
                                <span class="label-text font-bold">to workout in a group atmosphere</span>
                            </label>
                        </div>
                        <div class="form-control">
                            <label class="flex items-center cursor-pointer">
                                <input type="radio" name="radio-10" class="radio radio-lg checked:bg-primary mr-2"
                                       value="personal"
                                       v-model="form.training_style"
                                       @change="goToSlide(1)" />
                                <span class="label-text font-bold">personal coaching and guidance</span>
                            </label>
                        </div>
                    </div>
                </Slide>
                <Slide :key="1">
                    <div class="grid w-full place-content-center">
                        <ul class="steps mb-2">
                            <li class="step step-secondary"></li>
                            <li class="step step-secondary"></li>
                            <li class="step"></li>
                            <li class="step"></li>
                            <li class="step"></li>
                        </ul>
                        <div class="font-bold text-lg text-left mb-2">What do you want to focus on</div>
                        <div class="form-control mb-2">
                            <label class="flex items-center cursor-pointer">
                                <input type="checkbox" class="checkbox checkbox-primary mr-2" value="cardio" v-model="form.focuses"/>
                                <span class="label-text font-bold">Cardiovascular fitness</span>
                            </label>
                            <p class="text-sm text-left mt-1 mb-2">The body's ability to take in and use oxygen</p>
                        </div>
                        <div class="form-control mb-2">
                            <label class="flex items-center cursor-pointer">
                                <input type="checkbox" class="checkbox checkbox-primary mr-2" value="weights" v-model="form.focuses"/>
                                <span class="label-text font-bold">Muscular strength</span>
                            </label>
                            <p class="text-sm text-left mt-1 mb-2">The maximum amount of force you can exert</p>
                        </div>
                        <div class="form-control mb-2">
                            <label class="flex items-center cursor-pointer">
                                <input type="checkbox" class="checkbox checkbox-primary mr-2" value="functional" v-model="form.focuses"/>
                                <span class="label-text font-bold">Muscular endurance</span>
                            </label>
                            <p class="text-sm text-left mt-1 mb-2">The ability to exert force repeatedly over a period of time</p>
                        </div>
                        <div class="form-control">
                            <label class="flex items-center cursor-pointer">
                                <input type="checkbox" class="checkbox checkbox-primary mr-2" value="mobility" v-model="form.focuses"/>
                                <span class="label-text font-bold">Flexibility & mobility</span>
                            </label>
                            <p class="text-sm text-left mt-1 mb-2">The ability of joints to move through a range if motion</p>
                        </div>
                        <div class="flex justify-center mt-2">
                            <button class="btn btn-sm w-32 mr-2" @click="goToSlide(0)">Back</button>
                            <button class="btn btn-sm btn-primary w-32" @click="goToSlide(2)">Next</button>
                        </div>

                    </div>
                </Slide>

                <Slide :key="2">
                    <div class="grid w-full place-content-center">
                        <ul class="steps mb-2">
                            <li class="step step-secondary"></li>
                            <li class="step step-secondary"></li>
                            <li class="step step-secondary"></li>
                            <li class="step"></li>
                            <li class="step"></li>
                        </ul>
                        <div class="font-bold text-lg text-left mb-2">How many times a week are you planning to work out?</div>
                        <div class="flex mt-2 flex-1 justify-center">
                            <button type="button" class="btn w-12 border-slate-200 bg-slate-100 text-slate-500 mr-1" :disabled="form.frequency === 1" @click="form.frequency--">-</button>
                            <input type="number" step="1" min="1" max="20"  class="form-control w-24 text-center" v-model.number="form.frequency">
                            <button type="button" class="btn w-12 border-slate-200 bg-slate-100 text-slate-500 ml-1" :disabled="form.frequency >= 20" @click="form.frequency++">+</button>
                        </div>
                        <div class="flex justify-center mt-2">
                            <button class="btn btn-sm w-32 mr-2" @click="goToSlide(1)">Back</button>
                            <button class="btn btn-sm btn-primary w-32" @click="goToSlide(3)">Next</button>
                        </div>
                    </div>
                </Slide>

                <Slide :key="3">
                    <div class="grid w-full place-content-center">
                        <ul class="steps mb-2">
                            <li class="step step-secondary"></li>
                            <li class="step step-secondary"></li>
                            <li class="step step-secondary"></li>
                            <li class="step step-secondary"></li>
                            <li class="step"></li>
                        </ul>
                        <div class="font-bold text-lg text-left mb-2">What is your budget?</div>
                        <div class="mt-2">
                            <input type="range" min="0" max="100" class="range range-info" step="10" v-model.number="form.budget" />
                            <div class="font-medium text-center mt-1 mb-1">${{ form.budget }} per week</div>
                        </div>
                        <div class="flex justify-center mt-2">
                            <button class="btn btn-sm w-32 mr-2" @click="goToSlide(2)">Back</button>
                            <button class="btn btn-sm btn-primary w-32" @click="goToSlide(4)">Next</button>
                        </div>
                    </div>
                </Slide>

                <Slide :key="4">
                    <div class="grid w-full place-content-center">
                        <ul class="steps mb-2">
                            <li class="step step-secondary"></li>
                            <li class="step step-secondary"></li>
                            <li class="step step-secondary"></li>
                            <li class="step step-secondary"></li>
                            <li class="step step-secondary"></li>
                        </ul>
                        <div class="font-bold text-lg text-left mb-2">Where do you live?</div>
                        <input type="text" id="match-input" placeholder="Start typing your address" class="rounded p-3 text-gray-800"><br>
                        <button class="btn btn-primary" @click="useCurrentLocation">
                            <Icon size="20"><MapMarkerAlt></MapMarkerAlt></Icon>
                            Use my current location
                            <span class="loading loading-bars loading-md" v-show="isFetchingLocation"></span>
                        </button>
                        <p class="mt-3 font-bold">We will take you to your results after this step</p>
                    </div>
                </Slide>
            </Carousel>
        </div>
    </div>
</template>


<script>
import {Icon} from "@vicons/utils";
import MapMarkerAlt from '@vicons/fa/MapMarkerAlt';
import '../../css/carousel.css';
import { Carousel, Slide } from 'vue3-carousel';
import {router, usePage} from '@inertiajs/vue3';
export default {
    components: {
        Carousel,
        Slide,
        Icon,
        MapMarkerAlt
    },
    data() {
        return {
            isFetchingLocation: false,
            storage: usePage(),
            form: {
                training_style: null,
                focuses: [],
                frequency: 3,
                budget: 20
            }
        }
    },
    computed: {
        matchResult() {
            let categories = this.storage.props.categories;

            if (this.form.budget < 40) {
                delete categories['F45'];
                delete categories['CrossFit'];
            }

            return Object.keys(categories);
        }
    },
    mounted() {
        this.initAutocomplete()
    },
    methods: {
        goToSlide(number) {
            this.$refs.carousel.slideTo(number)
        },
        async initAutocomplete() {
            const sessionToken = window.crypto && crypto.randomUUID ? crypto.randomUUID() : Math.random().toString(36);
            const { Place } = await google.maps.importLibrary("places");
            const input = document.getElementById("match-input");
            const options = {
                componentRestrictions: { country: this.$countryCode.toLowerCase() },
                fields: ['place_id', 'name', 'geometry', 'formatted_address'],
                languageCode: "en",
                regionCode: this.$countryCode,
                types: ['address'],
                sessionToken: sessionToken,
            };
            const autocomplete = new google.maps.places.Autocomplete(input, options);
            autocomplete.addListener("place_changed", (ev) => {
                const place = autocomplete.getPlace();
                if (place && place.geometry) {
                    router.get(route('places.search'), {
                        lat: place.geometry.location.lat(),
                        lng: place.geometry.location.lng(),
                        formatted_address: place.formatted_address
                    });
                } else {
                    alert("Sorry, please try another location");
                    console.error("Autocomplete place not found", document.getElementById("pac-input").value);
                }
            })
        },
        useCurrentLocation() {
            this.isFetchingLocation = true;
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        router.get(route('places.search'), {
                            lat: position.coords.latitude,
                            lng: position.coords.longitude,
                            categories: this.matchResult
                        });
                    },
                    () => {
                        // something went wrong getting the location
                        router.get(route('places.search'));
                    },
                );
            } else {
                // Browser doesn't support Geolocation
                router.get(route('places.search'));
            }
        }
    }
}
</script>
