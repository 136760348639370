<script setup>
import { ref, onMounted } from 'vue'
import heroImage from '@/assets/logo/base_logo_transparent_background.png'
import Card3d from 'card3d'
import Match from "@/Components/Match.vue";

const effectRef = ref(null)
onMounted(() => {
    if (effectRef.value) {
        new Card3d(effectRef.value, { perspective: 1000, fullPageListening: true })
    }
})
</script>


<template>
    <section class="py-8 lg:py-20" data-theme="cupcake">
        <div class="container">
            <div class="grid gap-12 lg:grid-cols-2">
                <div>
                    <h2 class="text-4xl font-black tracking-tighter lg:text-6xl lg:leading-none">
                        Get fitter sooner<br>
                        <span class="text-secondary">Find and compare the best fit for you</span>
                    </h2>
                    <p class="mt-8 text-lg">
                        Are you searching for the ideal place to kickstart your fitness journey? Look no further! Say goodbye to endless Google searches and tedious visits to multiple gyms. We've streamlined the process by compiling detailed information and reviews of various fitness providers in your area. Compare images, membership fees, amenities, equipment, travel distance, commute time and much more.
                    </p>
                    <div class="mt-16 inline-flex gap-3">
                        <a :href="route('places.search')" class="btn btn-secondary">Find a gym</a>
                        <a :href="route('about-us')" class="btn">More about FitChoice</a>
                    </div>
                </div>

                <div class="w-full">
                    <div
                        class="rounded-2xl p-3"
                        ref="effectRef"
                        data-card3d=""
                        data-card3d-perspective="1000"
                        data-card3d-full-page-listening="true"
                    >
                        <img alt="SaaS" class="rounded-lg" :src="heroImage" />
                    </div>
                </div>

            </div>
        </div>

        <Match></Match>

    </section>
</template>

